<template>
  <a-select
    :allow-clear="allowClear"
    :disabled="disabled"
    :mode="mode"
    :filter-option="false"
    :options="options"
    placeholder="请选择告警事件源"
    :show-arrow="showArrow"
    :value="value"
    @change="v => $emit('input', v)"
  ></a-select>
</template>

<script>
import { sourceTypes } from '@/utils'

export default {
  name: 'SourceTypeSelect',
  props: {
    allowClear: { type: Boolean, default: true },
    disabled: { type: Boolean, default: false },
    mode: { type: String, default: 'default' },
    showArrow: { type: Boolean, default: true },
    value: { type: String }
  },
  data () {
    return {
      options: sourceTypes()
    }
  }
}
</script>
